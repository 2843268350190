.row {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.savedCaseColumn {
  display: flex;
  gap: 10px;
}
