/* General Styles - Applies to all screen sizes */
.heroContainer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  max-width: 90vw;
}

.carouselImage {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.heroTitleBlock {
  padding: 2rem;
  margin-top: 1rem;
  border-radius: 0.625rem;
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
}

.buttonContainer {
  margin-top: 1rem;
  display: flex;
  gap: 0.625rem;
  justify-content: center;
}

.placeOrderButton {
  margin-top: 2rem;
  display: flex;
  gap: 0.625rem;
  justify-content: center;
}

.heroTextCard {
  position: absolute;
  text-align: left;
  top: 30vh;
  right: 5vw;
  border-radius: 0.625rem;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 30vw;
  max-width: 600px;
  margin: 0;
  font-weight: 400;
}

.heroTextCard p {
  margin: 0;
}

/* Large Desktop Screens (1920px and above) - 24"-27" monitors */
@media (min-width: 1920px) {
  .heroContainer {
    max-width: 90vw;
  }

  .heroTextCard {
    font-size: 1rem;
    top: 53vh;
    right: 7vw;
    max-width: 1000px;
  }

  .carouselImage {
    height: 69vh;
  }
}

/* Ultra-Wide Screens (2160px and above) - 30"-34" ultra-wide monitors */
@media (min-width: 2160px) {
  .heroContainer {
    max-width: 95vw;
  }

  .carouselImage {
    height: 78vh;
  }

  .heroTextCard {
    top: 58vh;
    right: 5vw;
    font-size: 1.5rem;
    max-width: 1000px;
  }
}

/* Medium-Large Screens (1440px and below) - 15"-17" laptops */
@media (max-width: 1440px) {
  .carouselImage {
    height: 60vh;
  }

  .heroTitleBlock {
    max-width: 85vw;
  }

  .heroTextCard {
    right: 10vw;
  }
}

/* Medium to Large Screens (1440px - 1919px) - MacBook 14", MacBook 16", 21"-23" monitors */
@media (min-width: 1440px) and (max-width: 1919px) {
  .heroContainer {
    max-width: 90vw;
  }

  .carouselImage {
    height: 65vh;
  }

  .heroTextCard {
    top: 42vh;
    right: 8vw;
    font-size: 1rem;
    width: 35vw;
  }
}

/* Tablets and Small Laptops (1024px and below) - iPads (10"-12"), Surface Pro */
@media (max-width: 1024px) {
  .carouselImage {
    height: 60vh;
  }

  .heroTitleBlock {
    padding: 1.5rem;
  }

  .heroTextCard {
    position: relative;
    top: 2vh;
    right: 0;
    margin: 2vh auto;
    width: 85vw;
    max-width: 500px;
    padding: 0;
  }
}

/* Large Mobile Devices (430px and below) - iPhone 14 Pro Max, Samsung S22 Ultra */
@media (max-width: 430px) {
  .carouselImage {
    height: 55vh;
  }

  .heroTitleBlock {
    max-width: 90vw;
    padding: 1.25rem;
  }

  .heroTitleBlock h1 {
    font-size: 1.8rem;
  }

  .heroTitleBlock p {
    font-size: 1rem;
  }
}

/* Small Mobile Devices (375px and below) - iPhone SE */
@media (max-width: 375px) {
  .carouselImage {
    height: 50vh;
  }

  .heroTitleBlock h1 {
    font-size: 1.4rem;
  }

  .heroTitleBlock p {
    font-size: 0.85rem;
  }
}
